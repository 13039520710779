import React, { useEffect, useState, ReactNode } from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import cognitoConf from './cognito.json';
import { Amplify } from "aws-amplify";

interface SharedAuthenticatorProps {
    children: ReactNode;
}

const SharedAuthenticator: React.FC<SharedAuthenticatorProps> = ({ children }) => {
        Amplify.configure({
	    Auth: {
		Cognito: {
		    userPoolClientId: cognitoConf.userPoolWebClientId,
		    userPoolId: cognitoConf.userPoolId,
		}
	    }
	});


    return (
	<Authenticator loginMechanisms={["username", "email"]}>
	          {({ signOut, user }) => (
		      <>{children}</>
		  )}
	</Authenticator>
    );
};

export default SharedAuthenticator;

