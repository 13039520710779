import React, { useState, ChangeEvent, FormEvent } from 'react';
import  "./App.css";
import { ReactComponent as LogoDark} from "./logo-dark.svg";
import { ReactComponent as LogoLight} from "./logo-light.svg";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import MenuHeader from "./Menu";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Landing from "./Landing";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";

import ButtonGroup from '@mui/material/ButtonGroup';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import SharedAuthenticator from './SharedAuthenticator';
import Chat from './Chat';
import DocMarkdown from "./DocMarkdown";

declare module "@mui/material/styles" {
    interface Palette {
	backgrounds: Palette["primary"];
    }

    interface PaletteOptions {
	backgrounds?: PaletteOptions["primary"];
    }
}

const _darkTheme = createTheme({
    palette: {
	mode: "dark",
    },
});

const darkTheme = createTheme(_darkTheme, {
    palette: {
	action: {
	    hover: "#222222",
	},
	backgrounds: {
	    main: _darkTheme.palette.background.default,
	    light: "#222222",
	    dark: "#070707",
	    contrastText: "#242105",
	},
    },
});


const _lightTheme = createTheme({ palette: { mode: "light" } });

const lightTheme = createTheme(_lightTheme, {
    palette: {
	mode: "light",
	action: {
	    hover: "#e0e0ef",
	},
	primary: {
	    main: "#0d47a1",
	},
	backgrounds: {
	    main: "#f0f0ff",
	    light: "#f9f9ff",
	    dark: "#f0f0ff",
	    contrastText: "#242105",
	},
    },
});


interface IResult {
    success: boolean;
    error?: string;
    data?: any; // Define a more specific type based on the data structure
    data_type?: string;
}


const App: React.FC = () => {
    const getSavedTheme = () => {
	if(localStorage.getItem("isDark") === "true") {
	    return darkTheme;
	}
	return lightTheme;
    }
    
    const [error, setError] = useState<string>('');
    const [data, setData] = useState<any>();
    const [theme, setTheme] = useState(getSavedTheme());
    const [themeSwitch, setThemeSwitch] = useState(true)
    const [response, setResponse] = useState();

    const changeTheme = (isDark:boolean) => {
	localStorage.setItem("isDark", String(isDark));
	setTheme(isDark?lightTheme:darkTheme);
	setThemeSwitch(isDark);
    };

    


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
	event.preventDefault();

    };


    
    return (
	<Box sx={{height:"100vh", overflowX: "hidden"}}>
    <BrowserRouter>
	    <ThemeProvider theme={theme}>
	    <CssBaseline />
	    
	    <Box sx={{height: "100%", display: 'flex', flexDirection: 'column'}}>
	    <Box sx={{minHeight:"10%"}} >
	    <MenuHeader darkMode={themeSwitch} onDarkModeChange={(event: React.ChangeEvent<HTMLInputElement>) => {
		changeTheme(event.target.checked);
	    }}/>
	    </Box>
            <Routes>
            <Route path="/" element={<Landing />} />
            <Route
        path="/search"
        element={
            <SharedAuthenticator>
		<Chat />
                </SharedAuthenticator>
              }
            />
            <Route
              path="/terms-of-service"
              element={<DocMarkdown fileName="termsofservices" />}
            />
          </Routes>
	    </Box>

	    </ThemeProvider>
    </BrowserRouter>
	    </Box>
    );
};

export default App;
