import React, { useState, useRef, useEffect, KeyboardEvent } from 'react';
import { Box, TextField, List, ListItem, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Typography, Skeleton } from '@mui/material';
import { IMessage, MessageTypes, ITarget, IGuess, IRagQuestion, IResponse } from './types'; // Define these types based on your needs
import { DataGrid, GridRowSelectionModel, GridRowId } from '@mui/x-data-grid';
import fetcher from './fetcher';
import { useTheme, Theme } from "@mui/material/styles";

const randomID = (): string => {
  return Math.random().toString(36).substr(2, 9);
};


const Chat: React.FC = () => {
    const [messages, setMessages] = useState<IMessage[]>([]);
    const [inputText, setInputText] = useState('');
    const listRef = useRef<HTMLUListElement>(null);
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isInputDisabled, setIsInputDisabled] = useState<boolean>(false);
    const [datagridAnswers, setDatagridAnswers] = useState<{ [key: string]: GridRowId|undefined }>({});

  useEffect(() => {
      if (listRef.current) {
	  listRef.current?.scrollTo({ top: listRef.current.scrollHeight, behavior: 'smooth' });
    }
  }, [messages]);


    useEffect(() => {
	console.log("datagridAnswers effect");
	for (const [key, value] of Object.entries(datagridAnswers)) {
	    if(value === undefined) {
		setIsInputDisabled(true);
		return;
	    }
	}
	setIsInputDisabled(false);
    },[datagridAnswers]);
    
    const sendMessage = (text: string) => {
	const newMessage: IMessage = { type: 'text', content: text, isUser:true, id: randomID() }; 
	setMessages(prevMessages => [...prevMessages, newMessage]);
	setInputText('');
	setIsLoading(true);
	fetcher("/api/rag", "POST", {product:"zabbix", payload:text})
	    .then(  response => {
		if (response) {
		return response.json();
	    } else {
		throw new Error("Response is null");
	    }}).then(json => {
		setIsLoading(false);
		console.log("IResponse", json);
		const response = json as IResponse;
		if (response.success == false) {
		    return null;
		}
		const ragQuestion = response.message as IRagQuestion;
		console.log("is ragQuestion", ragQuestion);
		let blockInput = false;
		for (const target of ragQuestion.targets) {
		    blockInput = true;
		    const messageID = randomID();
		    
		    const newMessage: IMessage = {
			type: 'datagrid',
			id: messageID,
			content: target,
			isUser:false
		    };

		    
		    console.log("new message", newMessage);

		    setMessages(prevMessages => [...prevMessages, newMessage]);
		}
		if (blockInput) {
		    setIsInputDisabled(true);
		}

	}).catch(error => {
            setIsLoading(false);
            console.error("Fetching error: ", error);
	    const newMessage: IMessage = { type: 'text', content: 'Something bad happened :(', isUser:false, id: randomID() };
	    setMessages(prevMessages => [...prevMessages, newMessage]);
        });
    };

    const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
	if (event.key === 'Enter' && inputText.trim() !== '') {
	    event.preventDefault();
	    sendMessage(inputText.trim());
	}
    };

    const initExample = () => {
	setMessages(
	    [{ type: 'text', content: 'Hello, this is a text message!', isUser:false, id:randomID() },
	     { type: 'image', content: 'https://via.placeholder.com/150', isUser:true, id:randomID() },
	     {
		 type: 'datagrid',
		 id:randomID(),
		 isUser:false,
		 content: {
		     "name": "hstgrp",
		     "user_input": "linux hosts",
		     "explanation": "Plural form 'hosts' indicates a group, falls under 'hstgrp'.",
		     "id_name": "groupid",
		     "guesses": [
			 {"score": 65, "result": "Linux servers", "id_": 2, "field": null},
			 {"score": 62, "result": "Discovered hosts", "id_": 5, "field": null},
			 {"score": 37, "result": "Virtual machines", "id_": 6, "field": null},
			 {"score": 34, "result": "Hypervisors", "id_": 7, "field": null},
			 {"score": 26, "result": "Templates/Server hardware", "id_": 11, "field": null}
		     ]
		 }
	     }]
	);
    }
	

    useEffect(() => {initExample()}, []);
    
    const renderMessage = (message: IMessage) => {
	const background = (message.isUser)? theme.palette.backgrounds.dark:theme.palette.backgrounds.light;
	return <Box sx={{borderRadius: '1ex', backgroundColor: background, flex:1, padding:"2em"}}> {_renderMessage(message)}</Box>
    }
    const _renderMessage = (message: IMessage) => {
	switch (message.type) {
	    case 'text':
		return <Typography>{message.content}</Typography>;
	    case 'image':
		return <img src={message.content as string} alt="chat-img" style={{ maxWidth: '100%' }} />;
	    case 'table':
		// Example for rendering table, replace with actual data structure
		return (
		    <TableContainer component={Paper}>
			<Table size="small" aria-label="simple table">
			<TableBody>
			{(message.content as string[][]).map((row, i) => (
			    <TableRow key={i}>
				{row.map((cell, idx) => (
				    <TableCell key={idx}>{cell}</TableCell>
				))}
			    </TableRow>
			))}
		    </TableBody>
			</Table>
			</TableContainer>
		);
		
	    case 'datagrid':
		const rows = [];
		const target = message.content as ITarget;

		let id = 0;
		for (const guess of target.guesses) {
		    rows.push({id:id, score:guess.score, result:guess.result, id_:guess.id_})
		    id++;
		}
		const headers = [
		    {field:"score", headerName: "Score"},
		    {field:"result", headerName:"Description", flex:1},
		    {field:"id_", headerName:target.id_name}
		];
		
		return (
		    <Box style={{ width:"100%", flex:1 }}>
			<Typography>You asked for {target.user_input}: </Typography>
			<DataGrid
		    key={message.id}
		    rows={rows}
		    columns={headers}
		    checkboxSelection
		    disableMultipleRowSelection
		    onRowSelectionModelChange={(selectionModel: GridRowSelectionModel) => {
			setDatagridAnswers((prev) => ({
			    ...prev,
			    [message.id]: selectionModel[0],
			}));
		    }}
		    
		    sx={{flex:1}}
			/>
		    </Box>
		);	    
	    default:
		console.log('unsupported message type', message.type);
		return <Typography>Unsupported message type</Typography>;
	}
    };

    return (
	<Box sx={{height:"88vh", display:"flex", flexDirection: 'column', padding:"3em" }}>
	    <Box sx={{flex:1, overflowY: 'auto'}} ref={listRef}>
	    <List >
        {messages.map((msg, index) => (
            <ListItem key={index}>{renderMessage(msg)}</ListItem>
        ))}
	<ListItem key="loader"> {isLoading && <Skeleton variant="rounded" sx={{height:"3em", width:"100%"}} />} </ListItem>
	</List>
	    
	    </Box>
	    <Box sx={{ flexShrink: 0 }}>
	    <TextField
        fullWidth
        variant="outlined"
        placeholder="Type a message..."
        value={inputText}
        onChange={e => setInputText(e.target.value)}
        onKeyPress={handleKeyPress}
	disabled={isInputDisabled}
	    />
	    </Box>
	    </Box>
    );
};

export default Chat;
