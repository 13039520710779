import { useTheme, Theme } from "@mui/material/styles";
import { ReactComponent as LogoDark} from "./logo-dark.svg";
import { ReactComponent as LogoLight} from "./logo-light.svg";

import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

interface MenuProps {
  darkMode: boolean;
  onDarkModeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Menu: React.FC<MenuProps> = ({ darkMode, onDarkModeChange }) => {
    const theme = useTheme();
    const navigate = useNavigate();


    return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2 }}>
	    <Box onClick={() => navigate('/')} sx={{ width: '33%', textAlign: 'left', display:"flex", flexDirection:"row", alignItems:"center", cursor: 'pointer' }}>
	{theme.palette.mode === "dark" ? <LogoDark width="3em" height="3em" /> : <LogoLight width="3em"  height="3em" />}
            <Typography variant="h4" sx={{fontWeight:'bold'}}>Data-Talk</Typography>
      </Box>
      <Box sx={{ width: '33%', textAlign: 'center' }}>
        <Typography></Typography>
      </Box>
      <Box sx={{ width: '33%', textAlign: 'right' }}>
        <Typography></Typography>
        <Switch checked={darkMode} onChange={onDarkModeChange} />
      </Box>
    </Box>
  );
};

export default Menu;
