import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import { useTheme } from "@mui/material/styles";
import img_chat from "./undraw_chat_re_re1u.svg";
import img_control_panel from "./undraw_control_panel_re_y3ar.svg";
import img_messaging from "./undraw_messaging_re_pgx8.svg";
import TransitionOnVisible from "./TransitionOnVisible";
import { getStateSetter } from "./Context";
import { snackMessage, SnackProps } from "./utils";
import { useNavigate } from 'react-router-dom';

function LandingPage() {
    const theme = useTheme();
    const navigate = useNavigate();

    return (
	<Box sx={{flex:1}} >
	    <ParallaxProvider>

            <Grid
        container
        alignItems="center"
        justifyContent="center"
        spacing={4}
        py={3}
	sx={{padding:"2em", height:"100vh"}}
        >
            <Grid
        item
        md={12}
        lg={6}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        >
            <Grid item>
            <Typography
        sx={{
            textTransform: "uppercase",
            "&::first-letter": {
                fontSize: "1.1em",
            },
	    fontSize: {
		xs: '2rem',
		md: '3rem'
	    },

            fontWeight: "bold",
            fontFamily: "Roboto",
            WebkitTextStrokeWidth: "1px",
        }}
            >
	    Your words, your data
        </Typography>

            <Typography
        sx={{
            textTransform: "uppercase",
            "&::first-letter": {
                fontSize: "1.1em",
            },
	    fontSize: {
		xs: '3rem',
		md: '4rem'
	    },
	    
            fontWeight: "bold",
            fontFamily: "Roboto",
            WebkitTextStrokeWidth: "2px",
        }}
        >
        Data-Talk 
        </Typography>
        </Grid>
            <Grid item >
            <Typography
        sx={{
            fontSize: "2rem",
            fontWeight: "bold",
            textAlign: "center",
        }}
        >
	Regain control of your Data
        </Typography>
            </Grid>
            <Grid item my={3}>
            <Button
        variant="contained"
	onClick={() => navigate('/search')}
            >
            Join Now, it is free :)
    </Button>
    </Grid>
    </Grid>
        <Grid
    item
    md={12}
    lg={6}
    container
    >
        <Fade in={true} timeout={2000}>
        <img
    src={img_chat}
    alt="matching people"
    style={{ maxWidth: "100%", height: "auto" }}
        />
        </Fade>
    </Grid>
    </Grid>
        <Grid
    container
    alignItems="center"
    justifyContent="center"
    spacing={8}
    py={12}
    px={6}
    sx={{ backgroundColor: theme.palette.backgrounds.dark}}

    >
        <Grid
    item
    md={12}
    lg={6}
    container
    direction="column"
    >
        <Grid item>
        <TransitionOnVisible
    kind="slide"
    timeout={1500}
    direction="right"
        >
        <Box>
        <Typography variant="h4">
	Regain control of your data:
    </Typography>
        <Box mt={2}>
        <Typography sx={{ fontSize: "1.5rem", lineHeight: "3rem"  }}>
        🗣️ Query you ERP, CRM, or any database in you own language
    </Typography>
        <Typography sx={{ fontSize: "1.5rem", lineHeight: "3rem"  }}>
        🧑‍💻 Reduce dependency on IT 
    </Typography>
        <Typography sx={{ fontSize: "1.5rem", lineHeight: "3rem"  }}>
    📊 Visualize data easily 
    </Typography>
        <Typography sx={{ fontSize: "1.5rem", lineHeight: "3rem"  }}>
    📈 Increase productivity
    </Typography>
    </Box>
    </Box>
    </TransitionOnVisible>
    </Grid>
    </Grid>
        <Grid
    item
    md={12}
    lg={6}
    container
    alignItems="center"
    justifyContent="center"
    >
        <TransitionOnVisible kind="fade">
        <img
    src={img_control_panel}
    alt="matching people"
    style={{ maxWidth: "100%", height: "auto" }}
        />
        </TransitionOnVisible>
    </Grid>
    </Grid>

        <Grid
    container
    alignItems="center"
    justifyContent="center"
    spacing={8}
    py={12}
    px={6}

    sx={{ backgroundColor: theme.palette.backgrounds.light }}
    >
        <Grid
    item
    md={12}
    lg={6}
    container
    direction="column"
    justifyContent="center"
    alignItems="center"
    >
        <TransitionOnVisible kind="fade">
        <img
    src={img_messaging}
    alt="matching people"
    style={{ maxWidth: "100%", height: "auto" }}
        />
        </TransitionOnVisible>
    </Grid>
        <Grid item>
        <TransitionOnVisible kind="slide" timeout={1500} direction="left">
        <Box>
        <Typography variant="h4">Data Protection, AI Precision</Typography>
        <Box mt={2}>
        <Typography sx={{ fontSize: "1.5rem", lineHeight: "3rem"  }}>
        🛡️ AI does not access your database
    </Typography>
        <Typography sx={{ fontSize: "1.5rem", lineHeight: "3rem" }}>
    🤝 Enhance decision-making
    </Typography>
        <Typography sx={{ fontSize: "1.5rem", lineHeight: "3rem" }}>
    ⏱️ Real-time query processing 
    success
    </Typography>
    </Box>
    </Box>
    </TransitionOnVisible>
    </Grid>
    </Grid>

    </ParallaxProvider>
    </Box>
);
}

export default LandingPage;
