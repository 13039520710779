import { fetchAuthSession } from 'aws-amplify/auth';

const fetcher = async (
    url: string,
    method: string = "GET",
    data: Record<string, any> | undefined = undefined
): Promise<Response | null> => {

    let token;
    
    try {
	const session = await fetchAuthSession();
	if (session === undefined || session.tokens === undefined) {
	    return null;
	}
	token = session.tokens.idToken;
    } catch (error) {
	console.error("Error getting ID token: ", error);
	return null;
    }

    return fetch(url, {
	method: method,
	headers: {
	    Accept: "application/json",
	    "Content-Type": "application/json",
	    "Authorization": `Bearer ${token}`
	},
	body: data ? JSON.stringify(data) : null,
    });
};

export default fetcher;
